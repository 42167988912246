/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';
import { MnAuthModule  } from '@mn/auth'

// ngxuploader
import { NgUploaderModule } from './MnFileUploadExternalApi';

//
import {MnFileUploadInput, MnFileUploadDrop} from './MnFileUploadDirectives'
import {MnFileDownloadService} from './MnFileDownloadService'

let directives = [
    MnFileUploadInput,
    MnFileUploadDrop,
];

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule,
        MnAuthModule,
        NgUploaderModule
    ],
    exports: directives,
    declarations: directives,
    entryComponents: [],
    providers: [MnFileDownloadService]
})
export class MnFileModule {
    constructor() {}
    /*static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnHelpModule,
            providers: [MnHelpService]
        };
    }*/
}

export class mn_file {
    static configure():any {
        return [
            MnFileModule
            /*MnFileModule.forRoot()*/
        ];
    }
}