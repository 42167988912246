// rxjs
import { Subscription } from 'rxjs/Subscription';

// angular
import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2, Optional, HostListener } from '@angular/core';

// ngxuploader
import { UploadOutput, UploadInput, NgUploaderService } from './MnFileUploadExternalApi';

// mn
import {MnBackend, MnUnsubscribe} from "@mn/core";
import {MnAuthService} from "@mn/auth";

//
import { MnFileUploadService } from "./MnFileUploadService";

export type MnFileUploadUrl = string | (() => string | Promise<string>);

export class MnFileUploadBase {
    protected mInputSubscription:Subscription;
    protected mOutputSubscription:Subscription;
    protected mUploadInput: any;
    protected mDone:EventEmitter<any> = new EventEmitter<any>();

    protected mUploader: NgUploaderService;
    protected mId:number;

    protected mUrl:MnFileUploadUrl;
    protected mAccept:string[] = [];
    protected mMultiple:boolean = false;

    constructor(public mElRef: ElementRef, protected mRenderer: Renderer2, private mBackend:MnBackend, private mAuth:MnAuthService, private mQueue:MnFileUploadService) {
        this.mUploadInput =  new EventEmitter<UploadInput>();
        if (this.mQueue) {
            this.mId = mQueue.register(this);
        }
    }

    onDestroy() {
        if (this.mQueue) {
            this.mQueue.deregister(this.mId);
        }
    }

    protected onInit() {
        this.mUploader = new NgUploaderService(1, ['*']);
        this.mOutputSubscription = this.mUploader.serviceEvents.subscribe((event: UploadOutput) => {
            this.onUploadOutput(event);
        });
        this.mInputSubscription = this.mUploader.initInputEvents(this.mUploadInput);
    }

    protected onUploadOutput(output: UploadOutput): void {
        console.log(output);

        if (output.type === 'allAddedToQueue') {
            this.resolveUrl(this.mUrl);
        } else if (this.mQueue) {
            this.mQueue.queueUpdate(this.mId,output);
        }
        if (output.type === 'done') {
            this.mDone.emit();
        }
    }

    protected startUpload(url:string) {
        this.mAuth.user().subscribe((user) => {
            const event: UploadInput = {
                type: 'uploadAll',
                url: this.mBackend.base() + url,
                method: 'POST',
                //data: { foo: 'bar' },
                headers: {
                    Authorization: "JWT " + user.token,
                }
            };
            this.mUploadInput.emit(event);
        });
    }

    protected resolveUrl(url_or_function:MnFileUploadUrl) {
        if (typeof url_or_function === 'string') {
            this.startUpload(url_or_function);
        } else {
            let url_result = url_or_function();
            if (typeof url_result === 'string') {
                this.startUpload(url_result);
            } else if ( url_result.then ) {
                url_result.then(
                    (url:string) => {
                        this.startUpload(url);
                    },
                    () => {
                        console.error("no url",url_or_function,url_result);
                    }
                )
            }
        }
    }

}

@Directive({
    selector: 'input[mn-file-upload-input]',
    host: {
        'type': 'file',
        '[style.display]': '"none"',
    }
})
@MnUnsubscribe()
export class MnFileUploadInput extends MnFileUploadBase{


    @Input() set url(v: MnFileUploadUrl) { this.mUrl = v; }
    get url():MnFileUploadUrl { return this.mUrl; }

    @Input() set accept(v:string[]) { this.mAccept = v; this.updateAccept(); }
    get accept():string[] { return this.mAccept; }

    @Input() set multiple(v:boolean) { this.mMultiple = v; this.updateMultiple(); }
    get multiple():boolean { return this.mMultiple; }

    @Output() done: EventEmitter<any> = this.mDone;

    constructor(mElRef: ElementRef, mRenderer: Renderer2, mBackend:MnBackend, mAuth:MnAuthService, @Optional() mQueue:MnFileUploadService) {
        super(mElRef,mRenderer,mBackend,mAuth,mQueue);
        this.mUploadInput =  new EventEmitter<UploadInput>();
    }

    ngAfterViewInit() {
        this.onInit();
        this.mElRef.nativeElement.addEventListener('change', this.fileListener, false);
        this.updateAccept();
        this.updateMultiple()
    }

    ngOnDestroy() {
        this.mElRef.nativeElement.removeEventListener('change', this.fileListener, false);
    }

    fileListener = () => {
        if (this.mElRef.nativeElement.files) {
            this.mUploader.handleFiles(this.mElRef.nativeElement.files);
        }
    };

    private updateAccept() {
        if (this.mAccept.length == 0) {
            this.mRenderer.removeAttribute(this.mElRef.nativeElement,'accept');
        } else {
            this.mRenderer.setAttribute(this.mElRef.nativeElement,'accept', this.mAccept.join(','));
        }
    }

    private updateMultiple() {
        if (this.mMultiple == true) {
            this.mRenderer.setAttribute(this.mElRef.nativeElement,'multiple', '');
        } else {
            this.mRenderer.removeAttribute(this.mElRef.nativeElement,'multiple');
        }
    }

}

@Directive({
    selector: '[mn-file-upload-drop]'
})
@MnUnsubscribe()
export class MnFileUploadDrop extends MnFileUploadBase{


    @Input() set url(v: MnFileUploadUrl) { this.mUrl = v; }
    get url():MnFileUploadUrl { return this.mUrl; }

    @Input() set accept(v:string[]) { this.mAccept = v; }
    get accept():string[] { return this.mAccept; }

    @Input() set multiple(v:boolean) { this.mMultiple = v; }
    get multiple():boolean { return this.mMultiple; }

    @Output() done: EventEmitter<any> = this.mDone;

    constructor(mElRef: ElementRef, mRenderer: Renderer2, mBackend:MnBackend, mAuth:MnAuthService, @Optional() mQueue:MnFileUploadService) {
        super(mElRef,mRenderer,mBackend,mAuth,mQueue);
        this.mUploadInput =  new EventEmitter<UploadInput>();
    }

    ngAfterViewInit() {
        this.onInit();
        this.mElRef.nativeElement.addEventListener('drop', this.stopEvent, false);
        this.mElRef.nativeElement.addEventListener('dragenter', this.stopEvent, false);
        this.mElRef.nativeElement.addEventListener('dragover', this.stopEvent, false);
    }

    ngOnDestroy() {
        this.mElRef.nativeElement.removeEventListener('drop', this.stopEvent, false);
        this.mElRef.nativeElement.removeEventListener('dragenter', this.stopEvent, false);
        this.mElRef.nativeElement.removeEventListener('dragover', this.stopEvent, false);
    }

    stopEvent = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
    };

    @HostListener('drop', ['$event'])
    public onDrop(e: any) {
        console.log(e);
        e.stopPropagation();
        e.preventDefault();
        const event: UploadOutput = { type: 'drop' };
        this.onUploadOutput(event);
        this.mUploader.handleFiles(e.dataTransfer.files);
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(e: Event) {
        if (!e) { return; }
        const event: UploadOutput = { type: 'dragOver' };
        this.onUploadOutput(event);
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(e: Event) {
        if (!e) { return; }
        const event: UploadOutput = { type: 'dragOut' };
        this.onUploadOutput(event);
    }

}

