"use strict";

// angular
import {Injectable} from '@angular/core';

// mn
import {MnAuthService} from '@mn/auth';


declare var saveAs;

function isFunction(functionToCheck) {
    var getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

function isPromise(objectToCheck) {
    if (objectToCheck.then) {
        return isFunction(objectToCheck.then)
    }
    return false;
}

function normalizeToPromise(value_or_function:any):Promise<any> {
    let value_or_promise = isFunction(value_or_function) ? value_or_function() : value_or_function;
    return isPromise(value_or_promise) ? value_or_promise : Promise.resolve(value_or_promise);
}

@Injectable()
export class MnFileDownloadService {

    constructor(private mAuth:MnAuthService) {
        console.log("constructor MnFileDownloadService");
    }

    public saveBlob( blob:Blob | (() => Blob | Promise<Blob>), filename:string | (() => string | Promise<string>) ):Promise<any>  {
        return normalizeToPromise(blob)
            .then( vblob => {
                blob = vblob;
                return normalizeToPromise(filename);
            })
            .then( vfilename => {
                saveAs(blob,filename);
                return "DOWNLOAD_SUCCESS";
            })
    }

    public saveText( text:string | (() => string | Promise<string>), filename:string | (() => string | Promise<string>), mime_type?:string ):Promise<any> {
        return this.saveBlob(
            () => {
                return normalizeToPromise(text).then(v => { return new Blob([v], {type: mime_type ? mime_type : "text/plain;charset=utf-8"}) });
            },
            filename,
        );
    }

    public saveFile( file:File | (() => File | Promise<File>), filename:string | (() => string | Promise<string>) ) {
        return this.saveBlob(
            () => {
                return normalizeToPromise(file);
            },
            filename
        );
    }

    public saveUrl( url:string | (() => string | Promise<string>), filename:string | (() => string | Promise<string>), mime_type?:string ) {
        return this.saveBlob(
            () => {
                return normalizeToPromise(url)
                    .then(v => {
                        return new Promise(
                            (resolve, reject) => {
                                this.mAuth.user().subscribe((user) => {
                                    if (user.authenticated) {
                                        let xhr = new XMLHttpRequest();
                                        xhr.open('GET', v, true);
                                        xhr.setRequestHeader('Authorization','JWT ' + user.token);
                                        xhr.responseType = 'blob';
                                        xhr.onreadystatechange = function() {
                                            console.log("xhr",xhr);
                                            if (xhr.readyState !== 4) return;
                                            if(xhr.status === 200)    // If we get an HTTP status OK (200), save the file using fileSaver
                                            {
                                                let blob = new Blob([this.response], {type: mime_type});
                                                setTimeout(()=>{resolve(blob)},1000);
                                            } else {
                                                console.error("Download Error",this);
                                                reject("DOWNLOAD_ERROR");
                                            }
                                        };
                                        xhr.send();
                                    } else {
                                        console.error("Download Authentication Error",this);
                                        reject("AUTHENTICATION_ERROR");
                                    }
                                });

                            }
                        );
                    })
                    .then( v => {
                        return v;
                    })
            },
            filename,
        );
    }
}
