/**
 * Created by joerg on 7/24/17.
 */


"use strict";

// rxjs
import 'rxjs/Rx';
import {Subject, BehaviorSubject, Subscription, Observable,AnonymousSubject} from 'rxjs/Rx';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/distinctUntilChanged';

// angular2
import {forwardRef, Directive, Input, ElementRef, Renderer, ContentChildren, QueryList, Injectable, Injector, SkipSelf, NgZone} from "@angular/core";

// mn
import {MnBackend, MnBrandService, MnDeepMerge, MnLazy} from "@mn/core"

// ngx-uploader
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from './MnFileUploadExternalApi';



@Injectable()
export class MnFileUploadService {


    private mQueue:any[] = [];

    private mComponentCount:number = 0;
    private mComponents:any = {};

    constructor() {
        console.log("constructor MnFileUploadService");
    }

    public register(comp:any):number {
        this.mComponentCount++;
        this.mComponents[this.mComponentCount] = comp;
        return this.mComponentCount;
    }

    public deregister(comp_id:number) {
        delete this.mComponents[comp_id];
    }

    public queueUpdate(comp_id:number, output: UploadOutput) {
        if (output.type === 'addedToQueue'  && typeof output.file !== 'undefined') {
            this.mQueue.push(output.file);
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.mQueue.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.mQueue[index] = output.file;
        } else if (output.type === 'removed') {
            this.mQueue = this.mQueue.filter((file: UploadFile) => file !== output.file);
        }
        this.mQueue = this.mQueue.filter(file => file.progress.status !== UploadStatus.Done);
        console.log(this.mQueue);
    }

    public queue() {
        return this.mQueue;
    }

    public queueCancel(qid:string) {

    }
    public queueRemove(qid:string) {

    }
    public queueRemoveAll(qid:string) {

    }

    /*private startUpload() {
        this.mAuth.user().subscribe((user) => {
            const event: UploadInput = {
                type: 'uploadAll',
                url: this.mBackend.base() + '/datasets/dataset/' + this.mDataset.id() + '/append/',
                method: 'POST',
                //data: { foo: 'bar' },
                headers: {
                    Authorization: "JWT " + user.token,
                    //'Content-Type':'application/json','Accept':'application/json'
                }
            };
            this.uploadInput.emit(event);
        });
    }


    /*cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }*/



}